/** @jsxImportSource minimal-view */

import { render, enableDebug, effect } from 'minimal-view'

effect.maxUpdates = 100000

import { App } from 'cowbell-lol'

render(
  <App
    dev={false}
    distRoot=""
    apiUrl="https://projects.cowbell.workers.dev"
  />
  , document.body)
